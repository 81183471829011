<div class="accordion accordion-flush" id="accordianTodoList">
  <p><strong>My Day Task</strong></p>
  <div *ngFor="let task of taskList; let i = index">
    <div class="accordion-item">
      <h2 class="accordion-header" [id]="'flush-TodoList' + i">
        <button class="accordion-button d-flex justify-content-between align-items-center" type="button"
          data-bs-toggle="modal" data-bs-target="#taskDetailsModal" (click)="openTaskModal(task)" [ngClass]="{
              'bg-light-yellow': task?.pickACategory === 'feasibility',
              'bg-light-green': task?.pickACategory === 'bid manager',
              'bg-light-orange': task?.pickACategory === 'other tasks'
            }">
          <div>
            <i class="bi bi-circle task-icon"></i>
            {{ task?.task }}
            <span *ngIf="task?.pickACategory">
              ({{ task?.pickACategory }})</span>
          </div>
          <div class="text-end">
            <span *ngIf="task?.dueDate" class="text-muted">
              Due Date: {{ task.dueDate | date : "MMM d, y" }}
            </span>
          </div>
        </button>
      </h2>
      <!-- <div [id]="'flush-collapse' + i" class="accordion-collapse collapse"
            [attr.aria-labelledby]="'flush-TodoList' + i" data-bs-parent="#accordianTodoList">
            <div class="accordion-body">
              <p>{{ task?.discription }}</p>
  
              <textarea class="form-control" placeholder="Enter Task Comments"
                [(ngModel)]="task.commentDetails"></textarea>
              <div class="text-end mt-1">
                <button type="button" class="btn btn-primary" (click)="addComment(task.commentDetails, task._id)">
                  Submit
                </button>
              </div>
            </div>
          </div> -->
    </div>
  </div>
  <div *ngIf="!taskList?.length">
    <app-no-record-found></app-no-record-found>
  </div>
</div>

<div class="modal fade" id="taskDetailsModal" tabindex="-1" aria-labelledby="taskModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4>{{ modalTask?.task }}</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>

      <div class="modal-body d-flex">
        <div class="flex-grow-1 pe-3">
          <div class="mb-3" *ngIf="modalTask?.discription">
            <h6><strong>Description</strong></h6>
            <p>{{ modalTask?.discription }}</p>
            <p *ngIf="!modalTask?.discription">No Record Found.</p>
          </div>

          <div class="mb-3" *ngIf="modalTask?.project">
            <h6><strong>Assigned Project</strong></h6>
            <a (click)="projectDetails(modalTask?.project?._id)" href="javascript:;">{{ modalTask?.project?.projectName
              }}</a>
          </div>

          <!-- Comments Section -->
          <div class="comments-section" *ngIf="!modalTask?.project?.projectName">
            <h6><strong>Comment</strong></h6>
            <div class="mb-2">
              <!-- Textarea for Adding Comment -->
              <textarea class="form-control" placeholder="Add a comment..." [(ngModel)]="newComment">
                </textarea>
              <button class="btn btn-sm btn-primary mt-2" (click)="addComment(newComment, modalTask?._id)">
                Comment
              </button>

              <!-- Display Existing Comments -->
              <ul class="SecondarytaskList mt-3" *ngIf="modalTask?.comments?.length > 0">
                <li *ngFor="let comment of modalTask.comments; let i = index" class="comment-item">
                  <div class="row align-items-center mb-3">
                    <div class="col">
                      <div class="d-flex justify-content-between">
                        <p class="mb-0">{{ comment?.userDetail?.name }}</p>
                        <small class="text-muted">{{
                          comment?.date | date : "dd/MM/yyyy, h:mm a"
                          }}</small>
                      </div>
                      <small class="text-muted d-block">{{
                        comment?.userDetail?.role
                        }}</small>
                    </div>
                  </div>
                  <p class="mb-1">{{ comment?.comment }}</p>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-12 col-xl-12 col-12" *ngIf="modalTask?.project?.projectName">
            <div class="card shadow-sm p-4">
              <!-- Header -->
              <div class="card-header bg-primary text-white mb-3">
                <h5 class="mb-0">Feasibility Status</h5>
              </div>

              <!-- Status Buttons -->
              <div class="d-flex justify-content-between flex-wrap mb-4">
                <button class="btn btn-outline-primary flex-fill m-1" [class.active]="status === 'InProgress'"
                  (click)="statusChange('InProgress')">
                  In-Progress
                </button>
                <button class="btn btn-outline-secondary flex-fill m-1" [class.active]="status === 'InHold'"
                  (click)="statusChange('InHold')">
                  In-Hold
                </button>
                <button class="btn btn-outline-success flex-fill m-1" [class.active]="status === 'Passed'"
                  (click)="statusChange('Passed')">
                  Pass
                </button>
                <button class="btn btn-outline-danger flex-fill m-1" [class.active]="status === 'Fail'"
                  (click)="statusChange('Fail')">
                  Fail
                </button>
                <button class="btn btn-outline-warning flex-fill m-1" [class.active]="status === 'DocumentsNotFound'"
                  (click)="statusChange('DocumentsNotFound')">
                  Document Not Found
                </button>
              </div>

              <!-- Dynamic Comment Section -->
              <div *ngIf="status" class="status-section">
                <h6 class="text-secondary mb-3">{{ status }} Comments</h6>

                <!-- Display Existing Comments -->
                <div *ngIf="commentData?.length" class="comment-list">
                  <div class="comment-item border rounded p-3 mb-2" *ngFor="let item of commentData">
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="text-muted"><strong>Comment:</strong> {{ item.comment }}</span>
                      <span class="text-muted"><strong>Date:</strong>
                        {{ item.date | date : "dd/MM/yyyy, h:mm a" }}</span>
                    </div>
                  </div>
                </div>

                <!-- Add New Comment -->
                <div class="add-comment d-flex gap-3 align-items-center mt-3">
                  <input type="text" class="form-control w-80" [formControl]="statusComment"
                    placeholder="Add your comment" />
                  <button class="btn btn-primary" type="button" (click)="pushStatus()">
                    <i class="bi bi-plus-lg"></i> Add
                  </button>
                </div>

                <!-- Fail Specific Fields -->
                <div *ngIf="status === 'Fail'" class="mt-4">
                  <!-- <h6 class="text-danger">Additional Details for "Fail"</h6> -->
                  <div class="d-flex flex-column gap-3">
                    <!-- File Upload -->
                    <!-- <div class="d-flex align-items-center">
                    <label for="failedReson" class="btn btn-outline-secondary me-3">
                      <i class="bi bi-upload"></i> Upload Document
                    </label>
                    <input type="file" id="failedReson" name="failedReson" hidden (change)="
                        uploadDocument($event, documentUploadType.failStatusImage)
                      " />
                  </div> -->

                    <!-- Fail Reason Dropdown -->
                    <select class="form-select" [formControl]="failStatusReason">
                      <option disabled selected hidden value="">
                        Select Fail Reason
                      </option>
                      <option value="Accreditation">Accreditation</option>
                      <option value="Time Constraints">Time Constraints</option>
                      <option value="Certification">Certification</option>
                      <option value="Case Study">Case Study</option>
                      <option value="Certifications from Subcontractor">
                        Certifications from Subcontractor
                      </option>
                      <option value="Delivery of goods/Hardware">
                        Delivery of goods/Hardware
                      </option>
                      <option value="Financial Conditions">
                        Financial Conditions
                      </option>
                      <option value="Not related">Not related</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />

          <div class="row justify-content-end mb-4">
            <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2 col-12 text-end">
              <a (click)="summaryDetail('save')" class="btn btn-dark w-100">Save</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>