<header id="header">
  <div class="container d-flex align-items-center">
    <a href="javascript:;" class="logo mee-auto"><img src="assets/img/westgate.svg" alt="" class="img-fluid" /></a>
    <!-- <nav id="navbar" class="navbar w-100">
      <ul class="mx-auto">
        <li>
          <a class="nav-link active" routerLink="/feasibility-user/feasibility-project-list"><i
              class="bi bi-dot text-danger p-22"></i> My Projects</a>
        </li>
        <li>
          <a class="nav-link active" routerLink="feasibility-todo-task"><i class="text-danger p-22"></i>TO DO TASKS
          </a>
        </li>
      </ul>
      <i class="bi bi-list mobile-nav-toggle"></i>
    </nav> -->

    <nav id="navbar" class="w-100 navbar">
      <ul class="mx-auto">
        <li class="nav-link noarrowdrop" *ngFor="let item of navUrlArr">
          <a [class.active]="(item.route.includes(router.url))" class="nav-link " data-toggle="tab"
            [routerLink]="item.route">
            <i *ngIf="item.title == 'Live Projects'" class="bi bi-dot text-danger p-22"></i>
            <span>{{item.title}}</span>
          </a>
        </li>
      </ul>

      <i class="bi bi-list mobile-nav-toggle"></i>
    </nav>


    <nav class="navbar">
      <div>
        <ul class="d-block">
          <li class="dropdown">
            <a href="javascript:;">
              <div class="login-avtar">
                <img src="assets/img/face1.jpg" />
              </div>
              <span class="mobile-hide">{{ loginUser?.userName }}</span>
              <i class="bi bi-chevron-down"></i>
            </a>
            <ul>
              <li>
                <a href="javascript:;" routerLink="/feasibility-user/profile">Profile Setting</a>
              </li>
              <li><a style="cursor: pointer" (click)="logout()">Logout</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>