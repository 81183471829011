<body class="body-welcome-page">
    <main id="main">
        <section class="p-0">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-6 col-lg-6 col-12 desktop-pt200">
                        <h1 class="Heading-h1"><strong>Welcome back!</strong> </h1>
                        <ul class="LinkList-nav">
                            <li> <a routerLink="/boss-user/project-list"><img src="assets/img/arrow-blue.png" />Upload the Projects</a></li>
                            <!-- <li> <a routerLink="/boss-user/foi-document-list"><img src="assets/img/arrow-blue.png" />Upload the FOI Docs</a></li> -->
                            <li> <a routerLink="/boss-user/mail-screenhot-add"><img src="assets/img/arrow-blue.png" />Upload the screenshot of mail
                                    sent</a>
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="col-lg-6 col-xl-6 col-12">
                        <div class="bosTeam-wrapper">
                            <img src="assets/img/welcome-page-team.png" />
                        </div>
                    </div> -->
                </div>
            </div>
        </section>
    </main>
</body>