<main id="main">
    <section>
        <div class="container-fluid">
            <div class="col-lg-12 col-xl-12 col-12">
                <div class="statistic-card">
                    <div class="card-body">
                        <h5 class="text-theme mb-0"><strong>Gap Analysis</strong></h5>
                        <div class="table-responsive mt-5 mb-3">
                            <table class="table table-striped align-middle">
                                <thead>
                                    <tr>
                                        <th width="200px">Tags</th>
                                        <th>Total Projects</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td *ngIf="!gapAnalysisData?.length" colspan="3">
                                            <app-no-record-found></app-no-record-found>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngFor="let item of gapAnalysisData | paginate: { itemsPerPage: pagesize, currentPage: page, totalItems: totalRecords }; let i = index">
                                        <td>{{ item?.tag }}</td>
                                        <td>{{ item?.projectCount }}</td>
                                        <td>
                                            <a class="btn btn-primary btn-sm" style="cursor: pointer;"
                                                data-bs-toggle="modal" data-bs-target="#viewAllProjects"
                                                (click)="showProjects(item?.projects)">View Total Projects</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row mt-3 align-items-center">
                            <div class="row">
                                <div class="pagination">
                                    <pagination-controls (pageChange)="paginate($event)"
                                        [idMaxSize]="100"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

<div class="modal fade" id="viewAllProjects" tabindex="-1" aria-labelledby="viewAllProjectsLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header" style="display: flex; justify-content: space-between">
                <h5 class="modal-title" id="ViewDocumentModalLabel">
                    View All Projects
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <table class="table table-striped align-middle">
                    <thead>
                        <tr>
                            <th>Project Name</th>
                            <th>Comment</th>
                            <th>Feasibility Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="!selectedProjects?.length">
                            <td colspan="4">
                                <app-no-record-found></app-no-record-found>
                            </td>
                        </tr>
                        <tr *ngFor="let project of selectedProjects">
                            <td><a (click)="projectDetails(project?._id)"
                                    href="javascript:;">{{project?.projectName}}</a></td>
                            <td>{{ project?.comment }}</td>
                            <td>{{ project?.status }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>