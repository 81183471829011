<main id="main">
    <section class="casestudy-section">
        <div class="container-fluid">
            <div class="sort-widget">
                <h6 class="p-600">Date Range</h6>
                <form class="row row-cols-sm-auto" (ngSubmit)="getDataByStatus()">
                    <div class="col-6">
                        <div class="input-group mb-2">
                            <input class="form-control" placeholder="yyyy-mm-dd" [formControl]="trackerStartDate"
                                ngbDatepicker #startDatePicker="ngbDatepicker" />
                            <button class="btn btn-outline-secondary bi bi-calendar3" (click)="startDatePicker.toggle()"
                                type="button"></button>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="input-group">
                            <input class="form-control" placeholder="yyyy-mm-dd" [formControl]="trackerEndDate"
                                ngbDatepicker #endDatePicker="ngbDatepicker" />
                            <button class="btn btn-outline-secondary bi bi-calendar3" (click)="endDatePicker.toggle()"
                                type="button"></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="container-fluid">
            <div class="row">
                <h5 class="text-theme"><strong>Feasibility Status</strong></h5>
                <div class="col-lg-12 col-xl-12 col-md-6 col-sm-6 col-12 mb-4" style="cursor: pointer">
                    <ul class="taskuserList d-flex flex-wrap list-unstyled">
                        <li *ngFor="let item of feasibilityData" class="me-3">
                            <div (click)="filter(item.status, 'feasibility')" (click)="selectStatus(item.status)"
                                [ngClass]="{ 'text-danger': selectedStatus === item.status }">
                                {{ item.status }} : {{ item.count }} : GBP
                                {{ item.value | number : "1.0-2" }}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="row">
                <h5 class="text-theme"><strong>Bid Status</strong></h5>
                <div class="col-lg-12 col-xl-12 col-md-6 col-sm-6 col-12 mb-4" style="cursor: pointer">
                    <ul class="taskuserList d-flex flex-wrap list-unstyled">
                        <li *ngFor="let item of bidData" class="me-3">
                            <div (click)="filter(item.status, 'bid')" (click)="selectBidStatus(item.status)"
                                [ngClass]="{ 'text-danger': selectedBidStatus === item.status }">
                                {{ item.status }} : {{ item.count }} : GBP
                                {{ item.value | number : "1.0-2" }}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="container-fluid">
            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12">
                <div class="table-responsive mt-5 mb-3">
                    <table class="table table-striped align-middle">
                        <thead>
                            <tr>
                                <th>Project Title</th>
                                <th>BOS ID</th>
                                <th style="cursor: pointer" (click)="sort('publishDate')">
                                    Publish Date <i class="bi bi-sort-up"></i>
                                </th>
                                <th style="cursor: pointer" (click)="duedatesort('dueDate')">
                                    Due Date & Time <i class="bi bi-sort-up"></i>
                                </th>
                                <th>Feasibility Status</th>
                                <th>Feasibility Assign User</th>
                                <th>Bid Status</th>
                                <th>Bid Assign User</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td *ngIf="!projectList?.length" colspan="14">
                                    <app-no-record-found></app-no-record-found>
                                </td>
                            </tr>

                            <tr *ngFor="
                    let item of projectList
                      | paginate
                        : {
                            itemsPerPage: pagesize,
                            currentPage: page,
                            totalItems: totalRecords
                          };
                    let i = index
                  ">
                                <td>
                                    <a href="javascript:;" (click)="projectDetails(item?._id)">{{
                                        item?.projectName
                                        }}</a>
                                </td>
                                <td v-align="middle">{{ item?.BOSID }}</td>
                                <td>{{ item?.publishDate | date : "dd/MM/yyyy" }}</td>
                                <td>{{ item?.dueDate | date : "dd/MM/yyyy, h:mm a" }}</td>
                                <td>{{ item?.status }}</td>
                                <td>{{ item?.assignFeasibilityUser[0]?.name }}</td>
                                <td>{{ item?.bidManagerStatus }}</td>
                                <td>{{ item?.assignBidmanager[0]?.name }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row mt-3 align-items-center">
                    <div class="row">
                        <div class="pagination">
                            <pagination-controls (pageChange)="paginate($event)"
                                [idMaxSize]="100"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>