import { Component } from '@angular/core';

@Component({
  selector: 'app-feasibility-manager',
  templateUrl: './feasibility-manager.component.html',
  styleUrls: ['./feasibility-manager.component.scss']
})
export class FeasibilityManagerComponent {

}
