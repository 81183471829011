<main id="main">
    <section>
        <div class="container-fluids mb-3">
            <div class="row align-items-center">
                <nav>
                    <ul class="projects-nav-tab">
                        <li routerLink="/project-manager/project/bid-manager-to-action"><a class="active">To action</a>
                        </li>
                        <li routerLink="/project-manager/project/bid-manager-in-progress"><a>In progress</a></li>
                        <li routerLink="/project-manager/project/bid-manager-completed"><a>Completed projects</a></li>
                    </ul>
                </nav>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-2 col-xl-2 col-md-12 col-sm-12 col-12 bg-grays">
                    <div id="profile-menu-nav"><br><br>

                        <!-- Category Dropdown -->
                        <div class="sort-widget mb-3">
                            <h6 class="p-600">Category</h6>
                            <ng-select style="padding: 0px !important" placeholder="Select Category"
                                class="form-select custom-ng-select-option" [items]="categoryList" bindLabel="category"
                                [multiple]="true" bindValue="category" [(ngModel)]="selectedCategories"
                                (change)="searchtext()" [addTag]="true" (add)="onItemAddCategory($event)">
                            </ng-select>
                        </div>

                        <!-- Industry Dropdown -->
                        <div class="sort-widget mb-3">
                            <h6 class="p-600">Industry</h6>
                            <ng-select style="padding: 0px !important" placeholder="Select Industry"
                                class="form-select custom-ng-select-option" [items]="industryList" bindLabel="industry"
                                [multiple]="true" bindValue="industry" [(ngModel)]="selectedIndustries"
                                (change)="searchtext()" [addTag]="true" (add)="onItemAddIndustry($event)">
                            </ng-select>
                        </div>

                        <!-- Project Type Dropdown -->
                        <div class="sort-widget mb-3">
                            <h6 class="p-600">Project Type</h6>
                            <ng-select style="padding: 0px !important;" placeholder="Select Project Type"
                                class="form-select custom-ng-select-option" [items]="projectTypeList"
                                bindLabel="projectType" [multiple]="true" bindValue="value"
                                [(ngModel)]="selectedProjectTypes" (change)="searchtext()">
                            </ng-select>
                        </div>

                        <!-- Client Type Dropdown -->
                        <div class="sort-widget mb-3">
                            <h6 class="p-600">Client Type</h6>
                            <ng-select style="padding: 0px !important;" placeholder="Select Client Type"
                                class="form-select custom-ng-select-option" [items]="clientTypeList"
                                bindLabel="clientType" [multiple]="true" bindValue="value"
                                [(ngModel)]="selectedClientTypes" (change)="searchtext()">
                            </ng-select>
                        </div>

                        <!-- Status Dropdown -->
                        <div class="sort-widget mb-3">
                            <h6 class="p-600">Status</h6>
                            <ng-select style="padding: 0px !important;" placeholder="Select Status"
                                class="form-select custom-ng-select-option" [items]="statusList" bindLabel="status"
                                [multiple]="true" bindValue="value" [(ngModel)]="selectedStatuses"
                                (change)="searchtext()">
                            </ng-select>
                        </div>

                        <!-- Expired Project Checkbox -->
                        <div class="sort-widget mb-3">
                            <h6 class="p-600">Expired Project</h6>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="expiredProject"
                                    [(ngModel)]="isExpired" (change)="searchtext()">
                                <label class="form-check-label" for="expiredProject">Expired</label>
                            </div>
                        </div>

                        <div class="sort-widget">
                            <h6 class="p-600">Publish Date Range</h6>
                            <form class="row row-cols-sm-auto">
                                <div class="col-6">
                                    <div class="input-group mb-2">
                                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp"
                                            [formControl]="publishStartDate" ngbDatepicker #d="ngbDatepicker" />
                                        <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()"
                                            type="button"></button>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input-group">
                                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp"
                                            [formControl]="publishEndDate" ngbDatepicker #d1="ngbDatepicker" />
                                        <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d1.toggle()"
                                            type="button"></button>
                                    </div>
                                </div>
                            </form>
                        </div><br>
                        <div class="sort-widget">
                            <h6 class="p-600">Submission Due Date Range</h6>
                            <form class="row row-cols-sm-auto">
                                <div class="col-6">
                                    <div class="input-group mb-2">
                                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp"
                                            [formControl]="submissionStartDate" ngbDatepicker #d2="ngbDatepicker" />
                                        <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d2.toggle()"
                                            type="button"></button>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input-group">
                                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp"
                                            [formControl]="submissionEndDate" ngbDatepicker #d3="ngbDatepicker" />
                                        <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d3.toggle()"
                                            type="button"></button>
                                    </div>
                                </div>
                            </form>
                        </div><br>
                        <!-- Slider for Value (GBP) -->
                        <div class="sort-widget">
                            <h6 class="p-600">Value (GBP)</h6>

                            <!-- Min and Max value input fields -->
                            <div class="input-fields">
                                <label for="min-value">Min Value (GBP): </label>
                                <input id="min-value" type="number" [(ngModel)]="minValue" (change)="changeRange()" />

                                <label for="max-value">Max Value (GBP): </label>
                                <input id="max-value" type="number" [(ngModel)]="maxValue" (change)="changeRange()" />
                            </div>

                            <!-- ngx-slider component -->
                            <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"
                                (userChange)="changeRange()">
                            </ngx-slider>
                        </div>
                    </div>
                </div>


                <div class="col-lg-10 col-xl-10 col-md-12 col-sm-12 col-12">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-lg-6 col-xl-6 col-12 mob-mb1">
                            <div class="form-group mb-0">
                                <span class="input-icon"><i class="bi bi-search"></i></span>
                                <input class="form-control control-search"
                                    placeholder="Search by BOS ID, Project name, Client name, Website, Notice reference, Link to notice"
                                    type="text" [(ngModel)]="searchText" (input)="searchtext()">
                            </div>
                        </div>
                        <div class="col-lg-1 col-xl-1 col-12 mob-mb1" style="margin-right: 350px; cursor: pointer;">
                            <i class="bi bi-info-circle-fill" data-bs-toggle="tooltip"
                                title="Search using BOS ID, Project name, Client name, Website, Notice reference"></i>
                        </div>
                    </div>
                    <div class="table-responsive mt-5 mb-3">
                        <table class="table table-striped align-middle">
                            <thead>
                                <tr>
                                    <th>Project Title</th>
                                    <th>BOS ID</th>
                                    <th style="cursor: pointer;" (click)="sort('publishDate')">Publish Date <i
                                            class="bi bi-sort-up"></i>
                                    </th>
                                    <th style="cursor: pointer;" (click)="duedatesort('dueDate')">Due Date & Time <i
                                            class="bi bi-sort-up"></i></th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td *ngIf="!projectList?.length" colspan="14">
                                        <app-no-record-found></app-no-record-found>
                                    </td>
                                </tr>

                                <tr
                                    *ngFor="let item of projectList |  paginate: { itemsPerPage: pagesize , currentPage: page, totalItems: totalRecords } ; let i = index">
                                    <td><a (click)="projectDetails(item?._id)"
                                            href="javascript:;">{{item?.projectName}}</a></td>
                                    <td v-align="middle">{{item?.BOSID}}</td>
                                    <td>{{item?.publishDate | date:'dd/MM/yyyy'}}</td>
                                    <td>{{item?.dueDate | date:'dd/MM/yyyy, h:mm a'}}</td>
                                    <td>{{item?.status}}</td>
                                    <!-- <td>&nbsp;&nbsp;
                            <a class="btn btn-primary btn-sm" style="cursor: pointer;"
                                (click)="editProjectDetails(item?._id)"><i
                                    class="bi bi-pencil-fill"></i></a>
                        </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row mt-3 align-items-center">
                        <div class="row">
                            <div class="pagination">
                                <pagination-controls (pageChange)="paginate($event)"
                                    [idMaxSize]="100"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>